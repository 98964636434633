import CanonicalHandler from "./components/CanonicalHandler";
import { HelmetProvider } from "react-helmet-async";
import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  Link,
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import { FaTelegramPlane } from "react-icons/fa";
import logoImg from "/Logo.webp";
import "./App.css";
import Spinner from "./components/Stuff/Spinner";
import RotatingQuotes from "./components/RotatingQuotes/RotatingQuotes.jsx";
import LoginPage from "./components/SignInRegister/LoginPage.jsx";
import RegisterPage from "./components/SignInRegister/RegisterPage.jsx";

const ChaosTimer = lazy(() => import("./components/Stuff/ChaosTimer"));
const LegacyPage = lazy(() => import("./components/LegacyPage/LegacyPage.jsx"));

const Resonance = lazy(
  () => import("./components/ResonancePage/Resonance.jsx"),
);
const EchoChambers = lazy(
  () => import("./components/EchoChambers/EchoChambers.jsx"),
);
const SeekersQueries = lazy(
  () => import("./components/SeekersQueries/SeekersQueries.jsx"),
);
const ChamberAdjustments = lazy(
  () => import("./components/ChamberAdjustments/ChamberAdjustments.jsx"),
);
const UserProfile = lazy(() => import("./components/Profile/UserProfile"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage.jsx"));
const AdminPage = lazy(() => import("./components/Admin/AdminPage.jsx"));
const AuralCodex = lazy(() => import("./components/Podcast/AuralCodex.jsx"));
const BitcoinAddressPopup = lazy(
  () => import("./components/BitcoinAddressPopup"),
);

function App() {
  useEffect(() => {
    // Load particles.js when the component mounts
    if (window.particlesJS) {
      window.particlesJS.load(
        "particles-js",
        "/particlesjs-config.json",
        () => {},
      );
    } else {
      // Dynamically add the script if not already loaded
      const script = document.createElement("script");
      script.src = "/particles.min.js";
      script.async = true;
      script.onload = () => {
        if (window.particlesJS) {
          window.particlesJS.load(
            "particles-js",
            "/particlesjs-config.json",
            () => {},
          );
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const nodeRef = React.useRef(null);
  const [isSoundOn, setIsSoundOn] = useState(true);

  const [isMysticalTheme, setIsMysticalTheme] = useState(() => {
    // Load theme preference from localStorage
    return localStorage.getItem("theme") === "mystical";
  });
  const [isBackgroundEnabled, setIsBackgroundEnabled] = useState(() => {
    // Load background preference from localStorage
    const savedBackgroundPreference = localStorage.getItem("backgroundEnabled");
    return savedBackgroundPreference === null
      ? true
      : savedBackgroundPreference === "true";
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const location = useLocation();

  // State for managing login and register modal visibility
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  // Persist theme and background preferences to localStorage
  useEffect(() => {
    localStorage.setItem("theme", isMysticalTheme ? "mystical" : "regular");
    localStorage.setItem("backgroundEnabled", isBackgroundEnabled);
  }, [isMysticalTheme, isBackgroundEnabled]);

  // Toggle functions
  const toggleSound = () => setIsSoundOn(!isSoundOn);
  const toggleTheme = () => setIsMysticalTheme(!isMysticalTheme);
  const toggleBackground = () => setIsBackgroundEnabled(!isBackgroundEnabled);

  // Modal switcher functions
  const toggleToRegister = () => {
    setIsLoginModalOpen(false);
    setIsRegisterModalOpen(true);
  };

  const toggleToLogin = () => {
    setIsRegisterModalOpen(false);
    setIsLoginModalOpen(true);
  };

  // Token authentication logic
  useEffect(() => {
    const token =
      localStorage.getItem("authToken") || sessionStorage.getItem("authToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsAuthenticated(true);
        setUserRole(decodedToken.role);
      } catch (error) {
        console.error("Invalid token", error);
        localStorage.removeItem("authToken");
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  // ProtectedRoute component for controlling access
  function ProtectedRoute({ children, isAuthenticated, requiredRole }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const token =
        localStorage.getItem("authToken") ||
        sessionStorage.getItem("authToken");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          setUserRole(decodedToken.role);
          setIsAuthenticated(true);
        } catch (error) {
          setIsAuthenticated(false);
        }
      }
      setLoading(false); // Stop loading after token is processed
    }, []);

    if (loading) {
      return <Spinner />;
    }

    if (!isAuthenticated) {
      setIsLoginModalOpen(true); // Open login modal if not authenticated
      return null; // Don't render protected content
    }

    if (requiredRole && userRole !== requiredRole) {
      return null; // Block access if the user doesn't have the required role
    }

    return children; // Render the protected content
  }

  ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    requiredRole: PropTypes.string,
  };

  // Handle logout
  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem("authToken");
    sessionStorage.removeItem("authToken");
    setIsAuthenticated(false);
  };

  // Handle link clicks with optional authentication check
  const navigate = useNavigate();
  const handleLinkClick = (e, route, requiresAuth = false) => {
    e.preventDefault();
    if (requiresAuth && !isAuthenticated) {
      setIsLoginModalOpen(true); // Show login modal if auth is required and not authenticated
    } else {
      navigate(route); // Navigate to the specified route
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("emailConfirmed") === "true") {
      setIsLoginModalOpen(true);
    }
  }, [location]);

  return (
    <HelmetProvider>
      <CanonicalHandler />
      <div ref={nodeRef} className="brotherhood">
        <div
          id="particles-js"
          className="background"
          style={{ display: isBackgroundEnabled ? "block" : "none" }}
        ></div>
        <div className="container">
          <div className="header">
            <div id="logo">
              <Link to="/">
                <img
                  src={logoImg}
                  alt="The Brotherhood"
                  width="100"
                  height="100"
                />{" "}
              </Link>
              <p>The Brotherhood</p>
              <h1>- Forge Your Path Beyond the Ordinary</h1>
            </div>
            <Suspense fallback={<Spinner />}>
              <ChaosTimer />
            </Suspense>
            {location.pathname !== "/" && (
              <div className="back-button">
                <Link to="/">
                  <svg
                    width="30"
                    height="47.31"
                    viewBox="0 0 30.000006 47.307671"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g transform="translate(-12.693941,-8.7471704)">
                      <text
                        style={{
                          fontSize: "36px",
                          display: "inline",
                          fill: "#ffd700",
                          strokeWidth: 4.69583,
                        }}
                        x="6.9309649"
                        y="50.312386"
                        transform="scale(1.0101538,0.98994826)"
                      >
                        <tspan
                          x="6.9309649"
                          y="50.312386"
                          style={{ strokeWidth: 4.69583 }}
                        >
                          𒀀
                        </tspan>
                      </text>
                    </g>
                  </svg>
                  Main Chamber
                </Link>
              </div>
            )}
          </div>
        </div>
        <main>
          <Suspense fallback={<Spinner />}>
            <Routes>
              {/* Echo Chambers Route */}

              <Route
                path="/EchoChambers"
                element={
                  <>
                    <CanonicalHandler
                      title="Echo Chambers | The Brotherhood"
                      description="Dive into the Echo Chambers and explore the mysteries of The Brotherhood."
                    />

                    <EchoChambers />
                  </>
                }
              />
              <Route
                path="/AuralCodex"
                element={
                  <>
                    <CanonicalHandler
                      title="Aural Codex | The Brotherhood"
                      description="Listen to the Aural Codex, The Brotherhood's official podcast."
                    />

                    <AuralCodex />
                  </>
                }
              />

              <Route
                path="/AuralCodex/:id"
                element={
                  <>
                    <CanonicalHandler
                      title="Aural Codex Episode | The Brotherhood"
                      description="Explore a specific episode of the Aural Codex, the official podcast of The Brotherhood."
                    />

                    <AuralCodex />
                  </>
                }
              />
              <Route
                path="/user"
                element={
                  <>
                    <CanonicalHandler
                      title="Profile | CV"
                      description="User profile and CV for The Brotherhood members."
                    />
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <UserProfile />
                    </ProtectedRoute>
                  </>
                }
              />
              {/* Resonance Route */}
              <Route
                path="/Resonance"
                element={
                  <>
                    <CanonicalHandler
                      title="Resonance | The Brotherhood"
                      description="Explore the Resonance page of The Brotherhood and delve deeper into our vision."
                    />
                    <Resonance isSoundOn={isSoundOn} />
                  </>
                }
              />

              {/* Seekers Queries Route */}
              <Route
                path="/SeekersQueries"
                element={
                  <>
                    <CanonicalHandler
                      title="Seekers Queries | The Brotherhood"
                      description="Seek answers with The Brotherhood."
                    />
                    <SeekersQueries isSoundOn={isSoundOn} />
                  </>
                }
              />

              {/* Chamber Adjustments Route */}
              <Route
                path="/ChamberAdjustments"
                element={
                  <>
                    <CanonicalHandler
                      title="Chamber Adjustments | The Brotherhood"
                      description="Adjust the sound and other settings in the Chamber."
                    />
                    <ChamberAdjustments
                      isSoundOn={isSoundOn}
                      toggleSound={toggleSound}
                      isMysticalTheme={isMysticalTheme}
                      toggleTheme={toggleTheme}
                      isBackgroundEnabled={isBackgroundEnabled}
                      toggleBackground={toggleBackground}
                    />
                  </>
                }
              />

              {/* Legacy Page */}
              <Route
                path="/Legacy"
                element={
                  <>
                    <CanonicalHandler
                      title="Our Legacy | The Brotherhood"
                      description="Learn about the legacy and history of The Brotherhood."
                    />
                    <LegacyPage isSoundOn={isSoundOn} />
                  </>
                }
              />

              {/* Admin Page (protected) */}
              <Route
                path="/AdminPage/*"
                element={
                  <>
                    <CanonicalHandler
                      title="Admin Control | The Brotherhood"
                      description="Admin control hub for managing The Brotherhood."
                    />
                    <ProtectedRoute
                      isAuthenticated={isAuthenticated}
                      requiredRole="admin"
                    >
                      <AdminPage />
                    </ProtectedRoute>
                  </>
                }
              />

              {/* 404 Not Found Page */}
              <Route
                path="*"
                element={
                  <>
                    <CanonicalHandler
                      title="404 Not Found | The Brotherhood"
                      description="Oops! The page you are looking for doesn't exist."
                    />
                    <NotFoundPage />
                  </>
                }
              />

              <Route
                path="/"
                element={
                  <>
                    <CanonicalHandler
                      title="The Brotherhood - Beyond The Veil Of Reality"
                      description="Beyond The Veil Of Reality"
                    />
                    <nav id="main-nav">
                      <ul>
                        <li className="separator">
                          Echoes of the Chamber & Codex
                        </li>
                        <li>
                          <Link
                            to="/EchoChambers"
                            onClick={(e) => handleLinkClick(e, "/EchoChambers")}
                          >
                            {isMysticalTheme ? "Echo Chamber" : "Live Chat"}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/AuralCodex"
                            onClick={(e) => handleLinkClick(e, "/AuralCodex")}
                          >
                            {isMysticalTheme ? "Aural Codex" : "Podcasts"}
                          </Link>
                        </li>

                        <li className="separator">
                          Unveil the Mystical Knowledge
                        </li>

                        <li>
                          <Link
                            to="/Resonance"
                            onClick={(e) => handleLinkClick(e, "/Resonance")}
                          >
                            {isMysticalTheme ? "Resonance" : "Resonance"}{" "}
                            {/* No change needed */}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/Legacy"
                            onClick={(e) => handleLinkClick(e, "/Legacy")}
                          >
                            {isMysticalTheme ? "Our Legacy" : "Our Legacy"}{" "}
                            {/* No change needed */}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/SeekersQueries"
                            onClick={(e) =>
                              handleLinkClick(e, "/SeekersQueries")
                            }
                          >
                            {isMysticalTheme ? "Seeker's Queries" : "FAQ"}
                          </Link>
                        </li>

                        <li className="separator">Pathway to Brotherhood</li>

                        {!isAuthenticated && (
                          <>
                            <li>
                              <button
                                className="logreg-link"
                                onClick={() => setIsLoginModalOpen(true)}
                              >
                                {isMysticalTheme
                                  ? "Access the Chamber"
                                  : "Sign In"}
                              </button>
                            </li>

                            <li>
                              <button
                                className="logreg-link"
                                onClick={() => setIsRegisterModalOpen(true)}
                              >
                                {isMysticalTheme
                                  ? "Become A Brother"
                                  : "Sign Up"}
                              </button>
                            </li>
                          </>
                        )}

                        {isAuthenticated && (
                          <li>
                            <button
                              className="link-button"
                              onClick={handleLogout}
                            >
                              {isMysticalTheme
                                ? "Depart the Chamber"
                                : "Sign Out"}
                            </button>
                          </li>
                        )}

                        <li className="separator">
                          Chamber Tuning & Adjustments
                        </li>
                        <li>
                          <Link
                            to="/user"
                            onClick={(e) => handleLinkClick(e, "/user", true)}
                          >
                            {isMysticalTheme ? "Personal Chamber" : "Profile"}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/ChamberAdjustments"
                            onClick={(e) =>
                              handleLinkClick(e, "/ChamberAdjustments")
                            }
                          >
                            {isMysticalTheme ? "Chamber Tuning" : "Appearance"}
                          </Link>
                        </li>
                        {isAuthenticated && userRole === "admin" && (
                          <li>
                            <Link
                              to="/AdminPage"
                              onClick={(e) => handleLinkClick(e, "/AdminPage")}
                            >
                              Control Hub
                            </Link>
                          </li>
                        )}
                        <li>
                          <BitcoinAddressPopup />
                        </li>
                        <li>
                          <a
                            href="https://t.me/brotherhoodfoundation"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Link to our Telegram"
                          >
                            <FaTelegramPlane />
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </>
                }
              />
            </Routes>
          </Suspense>
          {isLoginModalOpen && (
            <LoginPage
              isOpen={isLoginModalOpen}
              onClose={() => setIsLoginModalOpen(false)}
              setIsAuthenticated={setIsAuthenticated}
              setUserRole={setUserRole}
              toggleToRegister={toggleToRegister}
            />
          )}

          {isRegisterModalOpen && (
            <RegisterPage
              isOpen={isRegisterModalOpen}
              onClose={() => setIsRegisterModalOpen(false)}
              toggleToLogin={toggleToLogin}
            />
          )}
        </main>
        <footer>
          <RotatingQuotes />
        </footer>
      </div>
      {/* </CSSTransition> */}
    </HelmetProvider>
  );
}

export default App;
