import React from "react";
import "./Spinner.css"; // Add your CSS for spinner here

const Spinner = () => {
  return (
    <div className="multi-spinner-container">
      <div className="multi-spinner">
        <div className="multi-spinner">
          <div className="multi-spinner">
            <div className="multi-spinner">
              <div className="multi-spinner">
                <div className="multi-spinner"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
