import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./LoginRegister.css";
import { API_BASE_URL } from "../../apiConfig";
import PropTypes from "prop-types";

const RegisterPage = ({
  isOpen = false,
  onClose = () => {},
  toggleToLogin = () => {},
}) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    await registerUser({ password, email });
  };

  const registerUser = async (userData) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/RegisterPage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const data = await response.json();
        switch (response.status) {
          case 400:
            setErrorMessage("Please fill in all required fields.");
            break;
          case 409:
            setErrorMessage("Already Claimed");
            break;
          default:
            setErrorMessage(
              data.message || "An unexpected error occurred. Please try again.",
            );
            break;
        }
      } else {
        // Successful registration, prompt user to check their email for confirmation
        onClose(); // Close the modal upon successful registration
        alert(
          "Registration successful. Please check your email to confirm your account.",
        );
        toggleToLogin(); // Optionally, redirect to the login modal after successful registration
      }
    } catch (error) {
      setErrorMessage(
        "An unexpected error occurred. Please check your connection and try again.",
      );
    }
    setIsLoading(false);
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <button className="modal-close" onClick={onClose}>
        &times;
      </button>
      <Modal.Title>Heed the Call</Modal.Title>
      <Modal.Body>
        <form className="user-form" onSubmit={handleRegisterSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            autoComplete="email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            autoComplete="new-password"
            required
          />
          <input type="submit" value="Become A Brother" />
          {errorMessage && (
            <div className="message userreg-error-message">{errorMessage}</div>
          )}
          {isLoading && <div>Attempting...</div>}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="logreg-button"
          variant="link"
          onClick={toggleToLogin}
        >
          Access The Chamber
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RegisterPage.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  toggleToLogin: PropTypes.func,
};

export default RegisterPage;
